import { GameId } from '@/config/home/teaserTimeTable';
import {
  HomeTeaserPriority,
  findHomeTeaserPrioritySync,
} from '@/helpers/findHomeTeaserPriority';
import { JackpotData } from '@/helpers/getNextJackpot';
import { getNow } from '@/utilities/dateUtils';

export function findHomeTeaserHighlightSync(
  gameIds: readonly GameId[],
  fallback: GameId,
  jackpotData: JackpotData,
  now = getNow()
): GameId {
  const priorities = getAllPrioritiesSync(gameIds, jackpotData, now);
  if (priorities.length > 0) {
    const highlight = priorities.reduce(
      (last: HomeTeaserPriority, current) =>
        current.priority > last.priority ? current : last,
      priorities[0]
    );

    if (highlight.priority === 0) {
      const currentSlot = priorities.find((p) => p.currentSlot);
      return currentSlot ? currentSlot.gameId : highlight.gameId;
    }
    return highlight.gameId;
  }
  return fallback;
}

function getAllPrioritiesSync(
  gameIds: readonly GameId[],
  jackpotData: JackpotData,
  now = getNow()
): HomeTeaserPriority[] {
  return gameIds.map((id) =>
    findHomeTeaserPrioritySync(id as GameId, jackpotData, now)
  );
}
