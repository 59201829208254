import { videoConfig } from '@/config/video';
import { DateFormat, getNow, parse } from '@/utilities/dateUtils';

export function getCurrentLottoCountdown() {
  const now = getNow();
  const today = now.format(DateFormat.Dayname);
  const liveTime = videoConfig.liveTimes.find((t) => t.day === today) || null;

  if (liveTime === null) {
    return null;
  }

  const { day, time } = liveTime;
  const next = parse(`${day} ${time}`, DateFormat.DayAndTime);
  const live = !next.isSame(now, 'day') ? next.add(1, 'week') : next;
  const start = live.clone().subtract(videoConfig.liveMinutesPrior, 'minutes');
  const end = live.clone().add(videoConfig.liveMinutesAfter, 'minutes');
  return now.isSameOrBefore(end) && now.isSameOrAfter(start)
    ? { live, start, end }
    : null;
}
