import {
  GameId,
  Priority,
  teaserTimeTable,
} from '@/config/home/teaserTimeTable';
import { findHomeTeaserTimeslot } from '@/helpers/findHomeTeaserTimeslot';
import { getGameById } from '@/helpers/getGameById';
import { JackpotData, NextJackpot } from '@/helpers/getNextJackpot';
import { getNow } from '@/utilities/dateUtils';

export interface HomeTeaserPriority {
  gameId: GameId;
  priority: Priority;
  currentSlot: boolean;
}

export function findHomeTeaserPrioritySync(
  gameId: GameId,
  jackpotData: JackpotData,
  now = getNow()
) {
  const config = getGameById(gameId);
  const slot = findHomeTeaserTimeslot(gameId, now);
  const slotPriority = slot ? slot.priority : 0;
  const jackpot = config && jackpotData ? jackpotData[gameId] : null;
  const jackpotPriority = jackpot ? findJackpotPriority(jackpot, gameId) : 0;
  return {
    gameId,
    priority: Math.max(slotPriority, jackpotPriority) as Priority,
    currentSlot: slot !== null && slot.game === gameId,
  };
}

function findJackpotPriority(
  jackpot: NextJackpot | null,
  gameId: GameId
): number {
  const jackpots = teaserTimeTable.getJackpots();
  const games =
    jackpot !== null
      ? jackpots.filter((item) => {
          return item.game === +gameId && Number(jackpot.value) >= item.value;
        })
      : [];

  return games.length > 0
    ? Math.max.apply(
        Math,
        games.map((g) => g.priority)
      )
    : 0;
}
