export enum CountdownItemVariant {
  Hours = 'Stunden',
  Minutes = 'Minuten',
  Seconds = 'Sekunden',
}

export const CountdownItem = ({
  variant,
  value,
}: {
  variant: CountdownItemVariant;
  value: string;
}) => {
  return (
    <div className="CountdownItem">
      <strong className="CountdownItem__value">{value}</strong>
      <small className="CountdownItem__label">{variant}</small>
    </div>
  );
};
