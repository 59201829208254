import Button from '@/components/Button';
import { ButtonVariant } from '@/components/ButtonVariant';
import Countdown from '@/components/Countdown';
import LinkWrapper from '@/components/LinkWrapper';
import Logo from '@/components/Logo';
import { LogoVariant } from '@/components/LogoVariant';
import { getCurrentLottoCountdown } from '@/helpers/getCurrentLottoCountdown';
import { getGameById } from '@/helpers/getGameById';
import { getNow } from '@/utilities/dateUtils';
import { useEffect, useState } from 'react';

export default function LiveTeaser() {
  const [countdown, setCountdown] = useState(null);
  useEffect(() => {
    setCountdown(getCurrentLottoCountdown());
  }, []);

  const countdownEnd = countdown ? countdown.live : null;
  const config = getGameById(4);
  const href = config ? config.urls.live || '#' : '#';
  const buttonText = countdownEnd
    ? countdownEnd.isSameOrBefore(getNow())
      ? 'Jetzt ansehen'
      : 'Zur LIVE-Ziehung'
    : 'Jetzt ansehen';

  return (
    <LinkWrapper className="LiveTeaser" href={href}>
      <div className="LiveTeaser__content">
        <Logo
          variant={LogoVariant.Lotto6aus49}
          className="LiveTeaser__logo"
          title="LOTTO 6aus49"
        />
        <strong className="LiveTeaser__title">
          Die LIVE-Ziehung beginnt in:
        </strong>
        <Countdown
          className="LiveTeaser__countdown"
          end={countdownEnd}
          hideHours={true}
        />
        <Button className="LiveTeaser__button" variant={ButtonVariant.Primary}>
          <span>{buttonText}</span>
        </Button>
      </div>
    </LinkWrapper>
  );
}
