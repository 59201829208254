import type { CmsEventTeaser } from '@/components/CmsInterfaces';
import LinkWrapper from '@/components/LinkWrapper';
import scaleImage from '@/utilities/scaleImage';
import Image from 'next/image';
import React from 'react';

export const HomeEventTeaser: React.FC<
  React.PropsWithChildren<CmsEventTeaser>
> = (props) => {
  const { title, link, imageDesktop, imageMobile } = props;

  return (
    <LinkWrapper className="HomeEventTeaser" href={link} title={title}>
      <picture style={{ width: '100%', height: 'auto' }}>
        <source
          media="(min-width: 882px)"
          srcSet={scaleImage({ url: imageDesktop, width: 600, quality: 50 })}
        />
        <Image
          alt={title}
          className="HomeEventTeaser__image"
          priority={false}
          src={scaleImage({ url: imageMobile, width: 600, quality: 50 })}
          width={0}
          height={0}
          sizes="100vw"
        />
      </picture>
    </LinkWrapper>
  );
};
