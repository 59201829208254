import { CmsEventTeaser } from '@/components/CmsInterfaces';
import { HomeEventTeaser } from '@/components/HomeEventTeaser';
import HomeGameTeaserItem, {
  HomeGameTeaserItemVariant,
} from '@/components/HomeGameTeaserItem';
import LiveTeaser from '@/components/LiveTeaser';
import { lotto6aus49 } from '@/config/game/lotto6aus49';
import { GameId, teaserTimeTable } from '@/config/home/teaserTimeTable';
import { findHomeTeaserHighlightSync } from '@/helpers/findHomeTeaserHighlight';
import { getCurrentLottoCountdown } from '@/helpers/getCurrentLottoCountdown';
import classnames from 'classnames';
import React from 'react';

interface HomeGameTeaserProps {
  jackpotData?: any;
  eventTeaser?: CmsEventTeaser;
}

interface HomeGameTeaserState {
  highlight: GameId;
  isLive: boolean;
  isMounted: boolean;
}

export enum HomeGameTeaserEventPriority {
  Hidden = 'hidden',
  High = 'high',
  Low = 'low',
}

export default class HomeGameTeaser extends React.Component<
  HomeGameTeaserProps,
  HomeGameTeaserState
> {
  private timerId: NodeJS.Timer;

  constructor(props) {
    super(props);
    this.state = {
      isLive: false,
      highlight: this.getHighlight(),
      isMounted: false,
    };
  }

  componentDidMount() {
    this.timerId = setInterval(() => {
      const upcomingState = {
        isLive: getCurrentLottoCountdown() !== null,
        highlight: this.getHighlight(),
        isMounted: true,
      };
      if (
        upcomingState.isMounted !== this.state.isMounted ||
        upcomingState.isLive !== this.state.isLive ||
        upcomingState.highlight !== this.state.highlight
      ) {
        this.setState(upcomingState);
      }
    }, 250);
  }

  public componentWillUnmount(): void {
    clearTimeout(this.timerId);
  }

  private showLiveTeaser(): boolean {
    return this.state.isLive && this.isHighlight(lotto6aus49.id as GameId);
  }

  private getHighlight(): GameId {
    if (this.state && this.state.isLive) {
      return lotto6aus49.id as GameId;
    }
    return findHomeTeaserHighlightSync(
      teaserTimeTable.games,
      teaserTimeTable.defaultHighlight,
      this.props.jackpotData
    );
  }

  private showEventTeaser(): boolean {
    return this.props.eventTeaser !== null && !this.showLiveTeaser();
  }

  private getItemVariant = (game: GameId): HomeGameTeaserItemVariant => {
    if (this.isHighlight(game)) {
      if (this.showLiveTeaser()) {
        return HomeGameTeaserItemVariant.Hidden;
      } else if (this.showEventTeaser()) {
        return HomeGameTeaserItemVariant.TwoThirds;
      } else {
        return HomeGameTeaserItemVariant.Full;
      }
    }
    return HomeGameTeaserItemVariant.OneFourth;
  };

  private getItemOrder = (game: GameId, index: number): number => {
    return this.isHighlight(game) ? -1 : index + 1;
  };

  private isHighlight(game: GameId): boolean {
    return game === this.state.highlight;
  }

  private renderLiveTeaser = (): JSX.Element | null => {
    return this.showLiveTeaser() ? (
      <HomeGameTeaserItem
        variant={HomeGameTeaserItemVariant.Full}
        order={1}
        game={-1}
        key="live"
      >
        <LiveTeaser />
      </HomeGameTeaserItem>
    ) : null;
  };

  private renderEventTeaser = (): JSX.Element | null => {
    const eventTeaser = this.props.eventTeaser as CmsEventTeaser;
    return this.showEventTeaser() && eventTeaser !== null ? (
      <HomeGameTeaserItem
        variant={HomeGameTeaserItemVariant.OneThird}
        game={-1}
        key="event"
      >
        <HomeEventTeaser {...eventTeaser} />
      </HomeGameTeaserItem>
    ) : null;
  };

  render() {
    return (
      <div
        className={classnames('HomeGameTeaser', {
          'HomeGameTeaser--is-live': this.state.isLive,
        })}
        key={JSON.stringify(this.state)}
      >
        {teaserTimeTable.games.map((game, i) => {
          const variant = this.getItemVariant(game);
          return variant !== HomeGameTeaserItemVariant.Hidden ? (
            <HomeGameTeaserItem
              key={game}
              game={game}
              variant={variant}
              order={this.getItemOrder(game, i)}
              isHighlight={this.isHighlight(game)}
              jackpotData={this.props.jackpotData}
            />
          ) : null;
        })}
        {this.renderLiveTeaser()}
        {this.renderEventTeaser()}
      </div>
    );
  }
}
