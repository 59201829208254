import {
  CountdownItem,
  CountdownItemVariant,
} from '@/components/CountdownItem';
import { getNow, getTimeDiff } from '@/utilities/dateUtils';
import classnames from 'classnames';
import React from 'react';

interface CountdownProps {
  end: moment.Moment | null;
  hideHours?: boolean;
  className?: string;
}

interface CountdownState {
  now: moment.Moment;
}

export default class Countdown extends React.Component<
  CountdownProps,
  CountdownState
> {
  constructor(props) {
    super(props);
    this.state = { now: getNow() };
  }

  componentDidMount(): void {
    if (this.props.end !== null) {
      this.init();
    }
  }

  UNSAFE_componentWillReceiveProps(props: CountdownProps): void {
    if (this.props.end === null && props.end !== null) {
      this.init();
    }
  }

  private init(): void {
    const timer = Countdown.start(() => {
      if (this.props.end && this.isFinished) {
        window.clearInterval(timer);
      } else {
        this.setState({ now: getNow() });
      }
    });
  }

  private static start(callback: Function): number {
    return window.setInterval(callback, 1000);
  }

  private get remainingTime() {
    if (this.props.end !== null && !this.isFinished) {
      return getTimeDiff(this.state.now, this.props.end);
    }
    return getTimeDiff(this.state.now, this.state.now);
  }

  private get isFinished(): boolean {
    return this.props.end ? this.state.now.isSameOrAfter(this.props.end) : true;
  }

  render(): JSX.Element {
    const { hours, minutes, seconds } = this.remainingTime;
    return (
      <div
        className={classnames('Countdown', this.props.className, {
          'Countdown--no-hours': this.props.hideHours,
        })}
      >
        {!this.props.hideHours && (
          <>
            <CountdownItem variant={CountdownItemVariant.Hours} value={hours} />
            <span className="Countdown__divider">:</span>
          </>
        )}
        <CountdownItem variant={CountdownItemVariant.Minutes} value={minutes} />
        <span className="Countdown__divider">:</span>
        <CountdownItem variant={CountdownItemVariant.Seconds} value={seconds} />
      </div>
    );
  }
}
