import {
  GameId,
  TimeTableDay,
  teaserTimeTable,
} from '@/config/home/teaserTimeTable';
import { DateFormat, getNow, parse } from '@/utilities/dateUtils';

export function findHomeTeaserTimeslot(
  gameId: GameId,
  now = getNow()
): TimeTableDay | null {
  const today = now.format(DateFormat.Dayname);
  const date = now.format(DateFormat.Default);
  const days = teaserTimeTable.getDays();
  const slot = days.find((item) => {
    const start = parse(`${date} ${item.start}`, DateFormat.DateAndTime);
    const end = parse(`${date} ${item.end}`, DateFormat.DateAndTime);
    return (
      item.game === gameId &&
      item.day === today &&
      now.isSameOrBefore(end) &&
      now.isSameOrAfter(start)
    );
  });
  return slot || null;
}
