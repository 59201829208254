import Modal from '@/components/Modal';
import ModalGluecksspiraleInfo from '@/components/ModalGluecksspiraleInfo';
import Swiper from '@/components/Swiper';
import WinningNumbersContainer from '@/components/WinningNumbersContainer';
import { eurojackpot } from '@/config/game/eurojackpot';
import { gluecksSpirale } from '@/config/game/gluecksSpirale';
import { keno } from '@/config/game/keno';
import { lotto6aus49 } from '@/config/game/lotto6aus49';
import type { OddsVariant } from '@/config/odds/OddsVariant';
import { useState } from 'react';

export default function WinningNumbersTeaser() {
  const [games, setGames] = useState({
    [lotto6aus49.shortname]: 1,
    [eurojackpot.shortname]: 1,
    [gluecksSpirale.shortname]: 1,
    [keno.shortname]: 1,
  });

  const newGames = {};

  function setDrawDate(shortname: string) {
    return function (date: Date) {
      newGames[shortname] = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      ).getTime();
      if (Object.entries(newGames).length === Object.entries(games).length) {
        setGames(newGames);
      }
    };
  }

  function getGameOrder() {
    return Object.entries(games)
      .sort((item1, item2) => {
        return item2[1] - item1[1];
      })
      .map((item) => item[0]);
  }

  return (
    <div className="WinningNumbersTeaser">
      <div className="WinningNumbersTeaser__container">
        <Swiper>
          {getGameOrder().map((shortname) => (
            <WinningNumbersContainer
              key={shortname}
              variant={shortname as OddsVariant}
              showLogoHeader={true}
              showFooter={false}
              showDate={true}
              showMore={true}
              small={true}
              clickable={true}
              setDrawDate={setDrawDate(shortname)}
            />
          ))}
        </Swiper>
      </div>
      <footer className="WinningNumbersTeaser__info">
        <i>Alle Angaben ohne Gewähr.</i>
        <strong onClick={() => Modal.open('gluecksspirale-info')}>
          {'***/****'} anzeigen
        </strong>
        <ModalGluecksspiraleInfo />
      </footer>
    </div>
  );
}
